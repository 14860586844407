<template>
  <div class="indexHomePage page-gray">
    <van-field
        readonly
        clickable
        label="门店"
        :value="store.name"
        placeholder="门店"
        @click="resultPicker = true"
    />
    <van-field
        readonly
        clickable
        label="学校"
        :value="school"
        placeholder="选择学校"
        @click="resultPicker = true"
    />
    <van-popup v-model="resultPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="resultList"
          title="选择学校"
          @cancel="resultPicker = false"
          @confirm="onResultConfirm"
      >
        <template #option="option">
          <div style="display: flex; flex-direction: column; align-items: center;">
            <div>{{ option.name }}</div>
          </div>
        </template>
      </van-picker>
    </van-popup>

    <div class="activitiesOfGoods">
      <div class="content">
        <div class="goodsList" v-for="(item,index) in productList" :key="index">
          <store-goods :product.sync="item" type="vertical"/>
        </div>
      </div>
    </div>

    <van-goods-action>
      <van-goods-action-icon icon="cart-o" text="购物车" :badge="cartList.length" @click="locationVerification"/>
      <van-goods-action-button type="danger" text="立即购买" @click="locationVerification"/>
    </van-goods-action>

    <van-popup v-model="show" round position="bottom" closeable close-icon="close" :style="{ height: '90%' }">
      <div class="cartContent">
        <div class="cartBody">
          <div class="cartCount">共{{ totalNum }}件商品</div>
          <van-checkbox-group v-model="cartCheck">
            <!-- 购物车卡片 -->
            <!--
                单规格 加个class；
                然后下面把一些东西隐藏起来
                这样就保证了两种卡片html内容一致， 就只需要循环一个卡片就可以了 ( <div v-for="item in cartList" :class="{item: true, simple: item.skuType === xxx}">)
            -->
            <div v-for="item in cartList" :key="item.cartId" :class="{item: true, simple: item.skuType === 2}">
              <div class="top">
                <div class="goodsInfo">
                  <van-swipe-cell :right-width="60">
                    <div class="goods">
                      <div class="radio">
                        <van-checkbox
                            :name="item"
                            :disabled=" ['1','2'].includes(item.cart.status)"
                            :icon-size="px2rem(19)"
                            checked-color="#D82225"
                        >
                        </van-checkbox>
                        <!--                                <img v-else src="./../../assets/images/cart/disabled_radio.png" alt="">-->
                      </div>
                      <div class="base">
                        <div class="imgBox">
                          <div class="notAvailable" v-if="item.cart.status==='1'">
                            <div class="circle">已过期</div>
                          </div>
                          <div class="notAvailable" v-if="item.cart.status==='2'">
                            <div class="circle">商品已下架</div>
                          </div>
                          <img
                              :src="item.catData.releaseProduct.image"
                              alt=""
                          />
                        </div>
                        <div class="right">
                          <div class="name van-multi-ellipsis--l2"
                               :style="{color: ['1','2'].includes(item.cart.status) ? '#ccc' : '#333'}">
                            {{ item.catData.releaseProduct.name }}
                          </div>
                          <div class="tagList">
                            <span class="tag" >门店自提</span>
                          </div>
                          <div class="price">
                            <div class="money">
                              <span>¥</span>
                              <span>{{ item.catData.totalPrice / item.catData.totalNum }}</span>
                            </div>
                            <div class="sku" @click="changeSku(item)">修改规格/数量</div>
                          </div>
                          <div v-if="!['1','2'].includes(item.cart.status)">
                            <van-count-down :time="item.cart.createTime" format="HH:mm:ss" @finish="finish"
                                            style="display: inline-block"/>
                            <span>后过期</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <template #right>
                      <van-button square
                                  @click="delShoppingCart(item.cart.cartId)"
                                  :style="{height: '100%', 'width': '36px', 'fontSize':'12px', padding: 0, marginLeft: '23px'}"
                                  text="删除" type="danger" color="#FF553E" class="delete-button"/>
                    </template>
                  </van-swipe-cell>

                  <div class="skuInfo">
                    <!-- 校服包才显示 -->
                    <collapse>
                      <template slot="head" scope="scope">
                        <div class="title">
                          <span>此单内包含商品:</span>
                          <img :class="{close: scope.open}" src="./../../assets/images/cart/arrow.png" alt="">
                        </div>
                      </template>

                      <template #content>
                        <div class="skuItem" v-for="(sku,index) in item.catData.skuProductData" :key="index">
                          <!-- 校服包才显示 -->
                          <img
                              src="./../../assets/images/cart/item.png"
                              alt=""
                          />
                          <div class="right">
                            <!-- 校服包才显示 -->
                            <div class="name van-ellipsis">
                              {{ sku.name }}
                            </div>
                            <div class="skuBox">
                              数量：{{ sku.num }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </collapse>

                  </div>
                  <div class="handle">
                    <div class="money">
                      <span>总价</span>
                      <span>¥</span>
                      <span>{{ item.catData.totalPrice }}</span>
                    </div>

                    <div class="count">
                      <span>数量:</span>
                      <span>X{{ item.catData.totalNum }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="bottom">
                <p>
                  <span class="key">配送方式</span>
                  <span class="value">门店配送</span>
                </p>
              </div>
            </div>

          </van-checkbox-group>
        </div>
        <!-- 这句话在 /cart/cartEmpty.vue里面还有一句 -->
        <p class="tips">温馨提示：系统将在每天凌晨自动清空购物车</p>
        <!-- 价格为分  :price="totalPrice" -->
        <van-submit-bar
            button-color="#D53A26"
            :price="totalPrice"
            label="总计:"
            button-text="去结算"
            @submit="submit"
            :disabled="cartCheck.length === 0"
        >
          <van-checkbox :icon-size="px2rem(18)" @click="computedAllCheck" v-model="allCheck"
                        checked-color="#D82225"><span
              class="allCheckText">全选</span></van-checkbox>
        </van-submit-bar>
      </div>

      <!-- 记得删除， 直接删除这个组件以及他的引用代码就可以了  -->
      <!--    <change-empty-state @select="toggleEmpty"/>-->

      <sku ref="sku"/>
    </van-popup>
  </div>
</template>

<script>
import storeGoods from './goods/storeGoods'
import Sku from '../goods/components/sku.vue';
import SKUTYPE from "../goods/components/skuType";
import wx from 'weixin-js-sdk';
import Collapse from "../cart/components/collapse";
import {commonMethods} from "../../util/util";
import {mapMutations} from 'vuex'

export default {
  name: "index",
  components: {
    storeGoods,
    Sku,
    Collapse
  },
  data() {
    return {
      resultList: [],
      resultPicker: false,
      school: '',//学校
      store: {},//门店
      productList: [],//商品列表
      cartList: [],//购物车数据
      show: false,//是否弹出
      cartCheck: [], //选中的购物车， 值为 van-checnbox的name属性
      allCheck: false,
    }
  },
  created() {
    let storeId = this.$route.params.storeId;
    this.$http.get(this.baseMallUrl + `/h5_store/queryStoreAndSchool/${storeId}`).then(res => {
      this.store = res.data.store;
      this.resultList = res.data.listSchool;
      this.init();
      this.queryStoreStudentInfo(storeId);
    })

  },
  watch: {
    cartCheck() {
      // 先看看有多少能选的
      let canSelectLen = this.cartList.filter(e => !['1', '2'].includes(e.cart.status)).length;
      this.allCheck = (canSelectLen === this.cartCheck.length) // 当可选数量跟已经选择的数量一样 就设置全选按钮选中
    }
  },
  computed: {
    /**
     * @returns 返回合计价格， 结果应该是分
     */
    totalPrice() {
      // 这里因为是静态数据 都是300一个卡片， 所以直接乘以300，
      // 真实数据应该在这里获取真实的数据
      let totalPrice = 0;
      this.cartCheck.forEach(cart => {
        totalPrice += cart.catData.totalPrice
      })
      return Math.ceil(totalPrice * 100);
    },
    totalNum() {
      let totalNum = 0;
      this.cartList.forEach(cart => {
        totalNum += cart.catData.totalNum
      })
      return totalNum;
    }
  },
  methods: {
    ...mapMutations(['setStudentInfo']),
    queryStoreStudentInfo(storeId){
      this.$http.get(this.baseMallUrl + `/h5_store/queryStoreStudentInfo/${this.store.storeId}`).then(res => {
        let studentInfo = res.data;
        if(studentInfo){
          this.setStudentInfo(res.data);
        }else{
          this.$router.replace({path:'/store/student',query:{storeId:storeId}})
        }
      })
    },
    init() {
      this.$http.get(this.baseMallUrl + `/h5_store/queryStoreShoppingCartProduct/${this.store.storeId}`).then(res => {
        let cartList = res.data;
        cartList.forEach((item) => {
          let date = new Date(item.cart.createTime);
          let min = date.getMinutes();
          date.setMinutes(min+30);
          item.cart.createTime = date.getTime() - Date.now();
        });
        this.cartList = cartList;
      })
    },
    onResultConfirm(item) {
      this.school = item.name;
      this.resultPicker = false
      //查询学校下的门店商品
      this.$http.get(this.baseProductUrl + `/h5_product/queryStoreProducts/${this.store.storeId}/${item.id}`).then(res => {
        this.productList = res.data;
      })
    },
    //过期
    finish(){
      this.init();
    },
    changeSku(item) {
      let skuType = SKUTYPE.CUSTOM;
      this.$refs.sku.id = item.catData.releaseProduct.id;
      this.$refs.sku.cartId = item.cart.cartId;
      this.$refs.sku.showPop((data) => {
        // data 这是结果
        console.log(data)
        this.init();
      }, skuType)
    },
    /**
     * 删除购物车
     */
    delShoppingCart(cartId) {
      this.$http.post(this.baseMallUrl + `/h5_mall/delShoppingCart/${cartId}`).then(res => {
        res
        this.$toast.success('删除成功');
        this.init();
      })
    },
    computedAllCheck() {
      this.$nextTick(() => {
        // 全选 反选
        if (this.allCheck) {
          // 先过滤被禁用的
          this.cartCheck = this.cartList.filter(e => !['1', '2'].includes(e.cart.status));
        } else {
          this.cartCheck = []
        }
      })
    },
    /**
     * 地理位置验证
     */
    locationVerification() {
      this.init();
      this.$http.get(this.baseMpUrl + "/mp-wx/getGeographicalPosition", {
        params: {
          url: location.href.split('#')[0],
        }
      }, {
        emulateJSON: true
      }).then(response => {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: response.data.appId, // 必填，公众号的唯一标识
          timestamp: response.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: response.data.nonceStr, // 必填，生成签名的随机串
          signature: response.data.signature,// 必填，签名
          jsApiList: ['getLocation']
          // 必填，需要使用的JS接口列表
        });

        // 注入配置信息
        wx.ready(() => {
          // 判断当前客户端版本是否支持指定 JS 接口
          wx.checkJsApi({
            jsApiList: ['getLocation'],
            success: function (res) {
              // 以键值对的形式返回，可用的api值true，不可用为false
              if (res.checkResult.getLocation === false) {
                this.$toast({message: '你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！'})
              }
            }
          });

          wx.getLocation({
            type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: (res) => {
              console.log(`${res.longitude},${res.latitude}`)
              // 计算两个经纬度之间的距离
              var point1 = new window.TMap.LatLng(res.latitude,res.longitude)
              commonMethods.getLal(this.store.address,(res) => {
                console.log('res',res)
              })
              var point2 = new window.TMap.LatLng(this.store.latitude, this.store.longitude);
              var path = [point1, point2];
              // 计算路径的实际距离
              var distance = window.TMap.geometry.computeDistance(path);
              alert(`直线距离为${distance.toFixed(2)}米`); // 输出距离 (单位: 米)
              this.show = true
            },
            cancel: function () {
              this.$toast({message: '用户拒绝授权获取地理位置'})
            }
          });

        });

        //错误信息
        wx.error(function (err) {
          console.log('err', err);
        });
      })
    },
    submit() {
      let reserveProductData = [];
      let anyProductData = [];
      this.cartCheck.forEach(cartEmpty => {
        if (cartEmpty.catData.releaseProduct.libckflag === 0) {
          reserveProductData.push(cartEmpty.catData)
        }
          if (cartEmpty.catData.releaseProduct.libckflag === 1) {
          anyProductData.push(cartEmpty.catData)
        }
      })

      localStorage.setItem('reserveProductData', JSON.stringify(reserveProductData));
      localStorage.setItem('anyProductData', JSON.stringify(anyProductData));
      localStorage.setItem('store', JSON.stringify(this.store));

      this.$router.push('/order/submit')
    }
  }
}
</script>

<style lang="less" scoped>

::v-deep .van-notice-bar {
  margin: 10px 10px 0;
  box-shadow: 2px 0px 20px 0px rgba(210, 197, 162, 0.3000);
  border-radius: 3px;
  height: 32px;
}

::v-deep .van-notice-bar__left-icon,
::v-deep .van-notice-bar__right-icon {
  color: #EF9B39;
}

.my-swipe {
  margin: 10px;

  img {
    height: 178px;
    border-radius: 5px;
    width: 100%;
  }
}

// ::v-deep .van-swipe__indicator.van-swipe__indicator--active {
//     width: 25px;
//     height: 5px;
//     background: #FFFFFF;
//     border-radius: 3px;
// }

.activitiesOfGoods {
  padding: 0 10px 1px 10px;

  .banner {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .content {
    margin-top: -14px;
    position: relative;
    z-index: 3;

    .goodsList {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 1px;
      justify-content: space-between;
      padding-bottom: 10px;
    }

    .more {
      border: 1px solid #C7C7C7;
      border-radius: 4px;
      display: block;
      width: 100px;
      margin: 2px auto 19px auto;


      font-size: 12px;
      color: #999999;
      line-height: 1;

      text-align: center;
      padding: 6px 0;
    }
  }


}

.handler {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px 17px 10px;

  > div {
    img {
      width: 172px;
    }
  }
}

.ad {
  margin: 0 10px;

  img {
    width: 100%;
  }
}

.after-sales {
  height: 90px;
  margin: 10px 10px 0 10px;
  position: relative;

  img {
    height: 90px;
    width: 100%;
  }

  .content {
    position: absolute;
    width: 50%;
    top: 0;
    bottom: 0;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      line-height: 1;
      font-size: 15px;
      font-weight: 500;
      color: #FEFEFE;
      text-shadow: 0px 0px 2px rgba(97, 97, 97, 0.28);
    }

    a {
      margin-top: 10px;
      padding: 5px 13px;
      background: #D53A26;
      border-radius: 100px;
      line-height: 19px;

      font-size: 10px;
      font-weight: 400;
      color: #FEFEFE;
      line-height: 10px;
    }
  }
}

.fixed_right_menu {
  position: fixed;
  right: 10px;
  top: 314px;
  z-index: 5;

  .item {
    width: 40px;
    height: 40px;
    background: #FEFEFE;
    box-shadow: 0px 1px 5px 0px rgba(177, 177, 177, 0.3000);
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0;

    img.dh {
      width: 19px;
      height: 21px;
    }

    img.kf {
      height: 24px;
      width: 23px;
    }

    img.gd {
      height: 11px;
      width: 20px;
    }
  }
}

.cartContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .cartBody {
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

  }

  .cartCount {
    padding: 10px 18px;
    line-height: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    padding: 10px 18px;

  }

  .van-checkbox-group {
    padding: 0 18px;

    .item {
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 15px;

      &.simple {
        .skuItem {
          margin-bottom: 0 !important;
        }
      }

      div.top {
        display: flex;
      }

      .radio {

        margin: 0 14px 0 0;

        > img {
          height: 19px;
          width: 19px;
        }
      }

      .goodsInfo {
        width: 100%;

        .goods {
          margin-top: 17px;
          display: flex;
          padding-right: 7px;
          align-items: center;
          padding-left: 13px;

          .base {
            display: flex;
          }

          .imgBox {
            width: 95px;
            height: 88px;
            border-radius: 5px;
            min-width: 95px;
            margin-right: 14px;
            position: relative;

            .notAvailable {
              position: absolute;
              width: 95px;
              height: 80px;
              border-radius: 5px;
              background-color: rgba(255, 255, 255, .4);
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;

              .circle {
                width: 41px;
                height: 41px;
                background: rgba(0, 0, 0, .5);
                border-radius: 50%;

                line-height: 41px;
                text-align: center;

                font-size: 13px;
                color: rgba(255, 255, 255, .9);
              }
            }

            img {
              width: 95px;
              height: 80px;
              border-radius: 5px;

            }
          }

          .right {
            padding-top: 3px;
            height: 80px;
            flex: 1;

            .name {
              line-height: 14px;
              font-size: 12px;
              font-weight: 400;
              color: #333333;
            }

            .tagList {
              display: flex;
              margin: 7px 0 11px 0;

              .tag {
                outline: 1px solid #F02336;
                border-radius: 2px;
                line-height: 10px;
                font-size: 10px;
                font-weight: 400;
                color: #F02336;
                padding: 2px 7px;
              }
            }

            .price {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 16px;

              .money {
                font-size: 0;

                span {
                  font-size: 12px;
                  font-weight: 500;
                  color: #D53A26;
                  margin-right: 3px;
                  line-height: 15px;

                  &:last-child {
                    font-size: 15px;
                    font-weight: bold;
                  }
                }
              }

              .sku {
                background: #EEEEEE;
                border-radius: 2px;
                padding: 3px 6px;

                line-height: 11px;
                font-size: 10px;
                font-weight: 400;
                color: #999999;
              }
            }
          }
        }

        .skuInfo {
          .title {
            padding: 25px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              line-height: 12px;
              font-size: 12px;
              font-weight: 400;
              color: #999999;
            }

            img {
              width: 9px;
              height: 5px;
              transition: all .2s;

              &.close {
                transform: rotateZ(180deg);
              }
            }
          }

          .skuItem {
            display: flex;
            margin-top: 17px;
            margin-bottom: 10px;

            > img {
              height: 5px;
              width: 5px;
              margin-right: 9px;
              margin-top: 4px;
            }

            .right {
              flex: 1;
              width: 0;

              .name {
                line-height: 12px;
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                margin-bottom: 10px;
              }

              .skuBox {
                background: #EEEEEE;
                border-radius: 2px;
                line-height: 11px;
                font-size: 10px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                padding: 6px 9px;

              }
            }
          }
        }

        .handle {
          display: flex;
          justify-content: space-between;
          padding: 13px 17px 12px 0;

          .money {
            span {
              font-weight: 500;

              &:first-child {
                font-size: 10px;
                color: #333333;
              }

              &:nth-child(2) {
                font-size: 12px;
                color: #D53A26;
                margin: 0 3px;
              }

              &:nth-child(3) {
                font-size: 15px;
                color: #D53A26;
              }
            }
          }

          .count {
            span {
              line-height: 10px;
              font-size: 10px;
              font-weight: 500;
              color: #333333;

              &:last-child {
                color: #D53A26;
                margin-left: 3px;
              }
            }
          }
        }
      }

      .skuInfo, .handle {
        padding-right: 7px;
        padding-left: 45px !important;
      }

      div.bottom {
        border-top: 1px solid #eee;
        padding: 7px 45px 10px 45px;

        p {
          line-height: 19px;

          .key {
            font-size: 10px;
            font-weight: 500;
            color: #333333;
            display: inline-block;
            width: 40px + 28px;
          }

          .value {
            font-size: 10px;
            font-weight: 400;
            color: #999999;
          }
        }

      }
    }
  }
}
</style>
