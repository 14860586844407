<template>
    <div>
        <div @click="open = !open">
            <slot name="head" :open="open"/>
        </div>
        <div class="body" :style="{height: open ? 'auto' : '0'}">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
export default {
    name: "Collapse",

    data() {
        return {
            open: true
        }
    }
}

</script>
<style lang="less" scoped>
.body {
    transition: height 0.3s ease-in-out;
    will-change: height;
    overflow: hidden;
    height: auto
}
</style>